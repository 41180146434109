<template>
  <div class="flex flex-col justify-center items-center py-6 md:py-0 md:px-6 md:flex-row md:gap-16">
    <header class="flex flex-col items-center gap-4 w-full md:w-[400px]">
      <img
        class="header__icon-dietela"
        src="https://ik.imagekit.io/dietela/pwa_webp/new_landing_page/logo-color@3x.webp?updatedAt=1689904664797"
        alt="icon logo dietela"
      >
      <img
        class="header__icon-login"
        :src="iconLogin"
        alt="icon login dietela"
      >
    </header>
    <main class="flex flex-col items-center justify-center w-full px-5 md:px-0 md:py-16 md:h-screen md:overflow-auto md:w-[400px] md:scrollbar-hide">
      <div class="bg-dgreen w-full text-center text-white p-2.5 rounded-t-lg mt-4 text-sm font-medium md:mt-0">
        <h1>Kirim Ulang Verifikasi</h1>
      </div>
      <div class="flex flex-col w-full">
        <FormulateForm
          v-slot="{ isValid, isLoading }"
          v-model="formValues"
          class="tab-form"
          :debounce="50"
          @submit="handleSubmit"
        >
          <FormulateInput
            name="email"
            label="Email"
            :icon-prefix="iconEmail"
            :icon-prefix-style="{
              width: '20px'
            }"
            placeholder="Masukkan email"
            validation="required|email"
            type="text"
          />
          <div class="tab-form-action">
            <d-button
              type="submit"
              :disabled="!isValid || isLoading"
            >
              Kirim
            </d-button>
          </div>
        </FormulateForm>
      </div>
    </main>
    <ModalVerification
      v-show="isModalVerificationOpen"
      retry
      :is-open="isModalVerificationOpen"
      :email="formValues.email"
    />
  </div>
</template>

<script >
import { ref } from '@vue/composition-api';
import iconLogin from '@/assets/ic-login.svg';
import iconEmail from '@/assets/ic-mail.svg';
import DButton from '@/components/elements/d-button.vue';
import ModalVerification from '@/components/widgets/modal-email-verification.vue';
import { ERROR } from '@/constants/list-error';
import { wait } from '@/utils/wait';
import { useActions } from '@/stores';
import { useToast } from '@chakra-ui/vue';
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const toast = useToast();
  const {
    resendVerification
  } = useActions('auth', ['resendVerification']);
  const isModalVerificationOpen = ref(false);
  const formValues = ref({
    email: ''
  });

  const handleSubmit = async _formValues => {
    try {
      await resendVerification({
        email: _formValues.email
      });
      isModalVerificationOpen.value = true;
      toast({
        status: 'success',
        title: 'Success',
        description: 'Sukses mengirimkan email verifikasi',
        duration: 3000
      });
    } catch (e) {
      if (e?.message === ERROR.EMAIL_ALREADY_VERIFIED) {
        toast({
          status: 'success',
          title: 'Success',
          description: e?.message,
          duration: 3000
        });
        await wait(1000);
        this.$router.push('/');
        return;
      }

      toast({
        status: 'error',
        title: 'Error',
        description: e?.message,
        duration: 3000
      });
    }
  };

  return {
    iconLogin,
    iconEmail,
    isModalVerificationOpen,
    formValues,
    handleSubmit
  };
};

__sfc_main.components = Object.assign({
  DButton,
  ModalVerification
}, __sfc_main.components);
export default __sfc_main;
</script>

<style scoped>
.header__icon-dietela{
  width: 110px;
}
.header__icon-login{
  width: 60%;
}
.tab-form {
  padding: 25px;
  border: 2px solid #008C81;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.tab-form-action {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}
.tab-form-action__link > a {
  font-size: 12px;
  font-weight: 500;
  color: #008C81;
  cursor: pointer;
}

.formulate-input::v-deep .formulate-input-errors  {
  color: red;
  margin-left: 20px;
  font-size: 14px;
  margin-top: 6px;
}

@media (min-width: 640px) {
  .tab-form {
    height: 100%;
    overflow: auto;

  }

  .tab-form::-webkit-scrollbar {
    display: none;
  }

  .header__icon-login{
    width: 100%;
    max-width: 450px;
  }

  .header__icon-dietela {
    width: 80px;
  }
}

.checkbox::v-deep div {
  font-size: 12px;
}
</style>
